<script lang="ts">
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { Dot, CirclePlus, LoaderCircle } from 'lucide-svelte'
	import { onMount } from 'svelte'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import { goto } from '$app/navigation'
	import { sendGTM } from '$lib/analytics'
	import {
		getHeatLossModalState,
		getMarketplaceDataState,
		getPreLeadModalState,
		getHeatLossModalOpenState
	} from '$lib/stores'
	import { t } from '$lib/translations'
	import { getCityNameFromZip } from '$lib/utils'

	const preLeadModalState = getPreLeadModalState()
	const houseHeatLossModalState = getHeatLossModalState()
	const houseHeatLossModalOpenState = getHeatLossModalOpenState()
	const marketplaceDataState = getMarketplaceDataState()

	let timer: NodeJS.Timeout | undefined

	function debounce<T extends unknown[]>(func: (...args: T) => void, timeout = 500) {
		return (...args: T): void => {
			if (timer) {
				clearTimeout(timer)
			}
			timer = setTimeout(() => {
				func(...args)
			}, timeout)
		}
	}

	let sendingForm = false

	export let ABleadBeforeMP = false // Should we need lead before the marketplace is shown?

	export const schema = z.object({
		floorSquare: z
			.string()
			.optional()
			.refine((value) => {
				if (!value?.length) return true
				return /^[0-9]+$/.test(value)
			}, t('forms.validations.number.typeError')),
		zip: z
			.string()
			.optional()
			.refine(
				(value) =>
					(value && value.length === 6 && (value.includes('-') || value.includes('–'))) ||
					(value && value.length === 5) ||
					!value,
				{
					message: t('web.forms.leaveContact.zip.validation')
				}
			),
		peopleCount: z
			.string()
			.optional()
			.refine((value) => {
				if (!value?.length) return true
				return /^[0-9]+$/.test(value)
			}, t('forms.validations.number.typeError'))
	})

	const defaultValues = {
		...defaults(zod(schema)),
		data: {
			...($marketplaceDataState?.floorSquare && { floorSquare: $marketplaceDataState.floorSquare }),
			...($marketplaceDataState?.zip && { zip: $marketplaceDataState.zip }),
			...($marketplaceDataState?.peopleCount && { peopleCount: $marketplaceDataState.peopleCount })
		}
	}

	const form = superForm(defaultValues, {
		SPA: true,
		validators: zodClient(schema),
		onChange: async (event) => {
			debounce(async () => {
				if (event) {
					if (event.target) {
						if (
							(event.path == 'floorSquare' && !isNaN(event.get('floorSquare'))) ||
							(event.path == 'zip' && !isNaN(event.get('zip')))
						) {
							updateHouseHeatLossField()
						}

						if (event.path == 'floorSquare' && !isNaN(event.get('floorSquare'))) {
							marketplaceDataState.update((state) => ({
								...state,
								floorSquare: event.get('floorSquare')
							}))
						}
						if (event.path == 'zip' && !isNaN(event.get('zip'))) {
							const zip = event.get('zip')
							const cityNameReal = await getCityNameFromZip(zip)
							marketplaceDataState.update((state) => ({
								...state,
								zip: zip,
								cityNameReal: cityNameReal
							}))
						}
					}
				}
			})()
		},
		onUpdate: async ({ form }) => {
			if (form.valid) {
				sendingForm = true
				sendGTM(
					'mini_calculator_submit_hvac',
					{},
					{
						calculator: {
							category: 'hvac',
							floor_area: $formData.floorSquare,
							zip_code: $formData.zip,
							persons: $formData.peopleCount,
							house_heat_loss: $houseHeatLossModalState.value
						}
					}
				)
				await marketplaceDataState.update((state) => ({
					...state,
					...($formData.floorSquare && {
						floorSquare: $formData.floorSquare
					}),
					...($formData.zip && { zip: $formData.zip }),

					...($formData.peopleCount && { peopleCount: $formData.peopleCount })
				}))
				if (ABleadBeforeMP) {
					await preLeadModalState.update((state) => ({
						...state,
						params: { ...$formData },
						open: true
					}))
					sendingForm = false
				} else {
					await goto(`/marketplace?formType=hvac`, { invalidateAll: true })
				}
			}
		}
	})

	const updateHouseHeatLossField = async () => {
		if (
			($formData.floorSquare !== $marketplaceDataState.floorSquare ||
				$formData.zip !== $marketplaceDataState.zip) &&
			$houseHeatLossModalState.value &&
			$houseHeatLossModalState.formData
		) {
			const heatLoss = await $houseHeatLossModalState.calculate({
				facade: $houseHeatLossModalState.formData.facade || '0',
				roof: $houseHeatLossModalState.formData.roof || '0',
				window: $houseHeatLossModalState.formData.window || '0',
				floorSquare:
					$formData.floorSquare?.toString() || $houseHeatLossModalState.formData.floorSquare || '0',
				zip: $formData.zip || $houseHeatLossModalState.formData.zip || ''
			})

			if (heatLoss) {
				$marketplaceDataState.houseHeatLoss = heatLoss
				$houseHeatLossModalState.value = heatLoss
			}
		}
	}

	const { form: formData, enhance: hvacEnhance } = form

	let loaded = false
	onMount(() => {
		loaded = true
		syncFromGlobal()
	})

	const houseHeatLossOptions = {
		LOW: t('web.modals.thermalLoss.tabs.low'),
		MEDIUM: t('web.modals.thermalLoss.tabs.medium'),
		HIGH: t('web.modals.thermalLoss.tabs.high'),
		CALCULATED: t('web.modals.thermalLoss.tabs.calculate')
	}

	$: $formData.houseHeatLoss = $houseHeatLossModalState.value || null
	$: $marketplaceDataState && syncFromGlobal()

	const syncFromGlobal = () => {
		$formData.zip = $marketplaceDataState.zip
		$formData.floorSquare = $marketplaceDataState.floorSquare
		$formData.peopleCount = $marketplaceDataState.peopleCount
	}
</script>

<form use:hvacEnhance class="px-2 sm:px-6" method="POST">
	<Field {form} name="floorSquare">
		<Control let:attrs>
			<Label
				class="grid w-full items-center overflow-hidden rounded-t-md border-b border-neutral-200 bg-white ring-inset focus-within:bg-neutral-100 sm:rounded-t-lg"
			>
				<span
					class="z-10 col-start-1 row-start-1 mx-2 flex h-[2em] w-fit items-center whitespace-nowrap rounded-md bg-white px-2 text-sm text-w-blue-950/70"
					>{@html t('web.forms.floorArea')}</span
				>
				<input
					{...attrs}
					bind:value={$formData.floorSquare}
					data-testid="input-hvac-floor-area"
					placeholder="100"
					class="text-md col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
				/>
				<FieldErrors class="-mt-4 py-1 pl-4 text-left text-xs text-w-red-500" />
			</Label>
		</Control>
	</Field>

	<Field {form} name="zip">
		<Control let:attrs>
			<Label
				class="grid w-full items-center overflow-hidden border-b border-neutral-200 bg-white ring-inset  focus-within:bg-neutral-100"
			>
				<span
					class="z-10 col-start-1 row-start-1 mx-2 flex h-[2em] w-fit items-center whitespace-nowrap rounded-md bg-white px-2 text-sm text-w-blue-950/70"
					>{@html t('web.forms.zipcode')}</span
				>
				<input
					{...attrs}
					type="text"
					bind:value={$formData.zip}
					data-testid="input-hvac-zipcode"
					placeholder={t('web.forms.zipcode.placeholder')}
					class="text-md col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
				/>
				<FieldErrors class="-mt-4 py-1 pl-4 text-left text-xs text-w-red-500" />
			</Label>
		</Control>
	</Field>

	<Field {form} name="peopleCount">
		<Control let:attrs>
			<Label
				class="grid w-full items-center overflow-hidden rounded-b-md bg-white ring-inset  focus-within:bg-neutral-100 sm:rounded-b-lg"
			>
				<span
					class="z-10 col-start-1 row-start-1 mx-2 flex h-[2em] w-fit items-center whitespace-nowrap rounded-md bg-white px-2 text-sm text-w-blue-950/70"
					>{@html t('web.forms.personsCount')}</span
				>
				<input
					{...attrs}
					type="text"
					bind:value={$formData.peopleCount}
					placeholder="3"
					data-testid="input-hvac-persons"
					class="text-md col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
				/>
				<FieldErrors class="-mt-4 py-1 pl-4 text-left text-xs text-w-red-500" />
			</Label>
		</Control>
	</Field>
	{#if $houseHeatLossModalState.value}
		{@const description = houseHeatLossOptions[$houseHeatLossModalState.valueType || 'CALCULATED']}
		<button
			type="button"
			data-testid="heatlossopenmodal"
			on:click={() => ($houseHeatLossModalOpenState = true)}
			class="mt-4 flex w-full items-center justify-between gap-10 rounded-lg bg-white/10 px-4 py-3 transition-colors hover:bg-white/20 active:hover:bg-white/20 {$houseHeatLossModalOpenState &&
				'bg-white/40'}"
		>
			<div class="text-left">{t('web.forms.zipcode.heatLoss')}</div>
			<div class="flex flex-wrap justify-end">
				{#if $houseHeatLossModalState.valueType && $houseHeatLossModalState.valueType !== 'CALCULATED'}
					<span>{description}</span><Dot color="white" />
				{/if}
				<span class="font-bold">{$houseHeatLossModalState.value} kW</span>
			</div>
		</button>
	{:else}
		<button
			data-testid="heatlossopenmodal"
			type="button"
			disabled={!loaded}
			on:click={() => ($houseHeatLossModalOpenState = true)}
			class="mt-4 flex w-full items-center justify-center gap-3 rounded-lg px-4 py-3 transition-colors hover:bg-white/20 focus:bg-white/40 active:hover:bg-white/20 md:focus:bg-white/20 {$houseHeatLossModalOpenState &&
				'bg-white/40'} {!loaded && 'pointer-events-none animate-pulse bg-white/20 text-w-blue-950'}"
		>
			{#if $houseHeatLossModalOpenState}
				<LoaderCircle size={18} color="#ffffff" class="animate-spin" />
			{:else}
				<CirclePlus size={18} color={loaded ? '#ffffff' : '#061c3e'} />
			{/if}{t('web.forms.addHeatLoss')}
		</button>
	{/if}

	<button
		class:pointer-events-none={sendingForm}
		class:opacity-70={sendingForm}
		data-testid="header-form-hvac-submit"
		type="submit"
		disabled={!loaded}
		class="btn-new btn-new-red mt-4 w-full whitespace-normal rounded-md sm:rounded-lg {!loaded &&
			'pointer-events-none animate-pulse bg-white/20 text-w-blue-950'}"
	>
		{#if sendingForm}
			<img src="/images/icons/loader-2.svg" class="w-[1.25em] animate-spin" alt="Loader icon" />{t(
				'web.forms.loading.devices'
			)}
		{:else}
			{t('web.forms.showHeatpumps')}
		{/if}
	</button>
</form>
