<script lang="ts">
	import { Select } from 'bits-ui'
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { Check, ChevronDown } from 'lucide-svelte'
	import { onMount } from 'svelte'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import { goto } from '$app/navigation'
	import { sendGTM } from '$lib/analytics'
	import { flyAndScale } from '$lib/transitions'
	import { t } from '$lib/translations'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { getMarketplaceDataState, getPreLeadModalState } from '$lib/stores'

	const preLeadModalState = getPreLeadModalState()
	const marketplaceDataState = getMarketplaceDataState()

	let sendingForm = false

	const heatTypeOptions: Record<string, string | undefined> = {
		'': t('web.forms.choose'),
		COAL: t('web.woltadvisor.houseHeatType.COAL'),
		COAL_I_II: t('web.woltadvisor.houseHeatType.COAL_I_II'),
		ELECTRO: t('web.woltadvisor.houseHeatType.ELECTRO'),
		GAS: t('web.woltadvisor.houseHeatType.GAS'),
		...(PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_DE' && {
			GAS_OLD: t('web.woltadvisor.houseHeatType.GAS_OLD')
		}),
		GAS_OLD: t('web.woltadvisor.houseHeatType.GAS_OLD'),
		GAS_CONDENSING: t('web.woltadvisor.houseHeatType.GAS_CONDENSING'),
		HEATPUMP: t('web.woltadvisor.houseHeatType.HEATPUMP')
	} as const

	type HeatTypeOptions = keyof typeof heatTypeOptions

	export let ABleadBeforeMP = false // Should we need lead before the marketplace is shown?
	export const schema = z.object({
		annualElectricityConsumption: z
			.string()
			.optional()
			.refine((value) => {
				if (!value?.length) return true
				return /^[0-9]+$/.test(value)
			}, t('forms.validations.number.typeError')),
		houseHeatType: z
			.enum(Object.keys(heatTypeOptions) as [HeatTypeOptions, ...HeatTypeOptions[]])
			.optional()
			.default(''),
		zip: z
			.string()
			.optional()
			.refine((value) => {
				if (!value?.length) return true
				return /^[0-9-–]+$/.test(value)
			}, t('web.forms.leaveContact.zip.validation'))
	})

	const defaultValues = {
		...defaults(zod(schema)),
		data: {
			...($marketplaceDataState?.houseHeatType?.length
				? {
						houseHeatType: $marketplaceDataState?.houseHeatType || ''
				  }
				: {
						houseHeatType: ''
				  }),
			formType: 'pv',
			...($marketplaceDataState?.annualElectricityConsumption && {
				annualElectricityConsumption: $marketplaceDataState.annualElectricityConsumption
			}),
			...($marketplaceDataState?.zip && { zip: $marketplaceDataState.zip })
		}
	}

	const form = superForm(defaultValues, {
		SPA: true,
		validators: zodClient(schema),
		onUpdate: async ({ form }) => {
			if (form.valid) {
				sendingForm = true
				sendGTM(
					'mini_calculator_submit_fve',
					{},
					{
						calculator: {
							category: 'pv',
							heating_source: $formData.houseHeatType,
							annual_consumption: $formData.annualElectricityConsumption,
							zip_code: $formData.zip
						}
					}
				)

				await marketplaceDataState.update((state) => ({
					...state,
					...($formData.annualElectricityConsumption && {
						annualElectricityConsumption: $formData.annualElectricityConsumption
					}),
					...($formData.houseHeatType && { houseHeatType: $formData.houseHeatType }),
					...($formData.zip && { zip: $formData.zip })
				}))
				if (ABleadBeforeMP) {
					await preLeadModalState.update((state) => ({
						...state,
						params: { ...$formData },
						open: true
					}))
					sendingForm = false
				} else {
					await goto(`/marketplace?formType=pv`, { invalidateAll: true })
				}
			}
			sendingForm = false
		}
	})
	const { form: formData, enhance } = form

	$: selectedHouseHeatType = {
		label: heatTypeOptions[$formData.houseHeatType],
		value: $formData.houseHeatType
	}
	$: $marketplaceDataState && syncFromGlobal()

	let loaded = false
	onMount(() => {
		loaded = true
		syncFromGlobal()
	})

	const syncFromGlobal = () => {
		$formData.zip = $marketplaceDataState.zip
		$formData.houseHeatType = $marketplaceDataState.houseHeatType
		$formData.annualElectricityConsumption = $marketplaceDataState.annualElectricityConsumption
	}
</script>

<form use:enhance class="px-2 sm:px-6" method="POST">
	<Field {form} name="houseHeatType">
		<Control let:attrs>
			<Label
				data-testid="heatTypeOptions-trigger"
				class="grid w-full items-center rounded-t-md border-b border-neutral-200 bg-white ring-inset focus-within:bg-neutral-100"
				><span
					data-testid="heatTypeOptions-label"
					class="z-10 col-start-1 row-start-1 mx-2 my-1 flex h-fit w-fit max-w-[50%] items-center whitespace-nowrap text-wrap rounded-md bg-white px-2 py-1 text-sm text-w-blue-950/70 {selectedHouseHeatType.value &&
						'max-w-full'}">{t('web.woltadvisor.houseHeatType')}</span
				>
				<Select.Root
					preventScroll={false}
					selected={selectedHouseHeatType}
					onSelectedChange={(selected) => {
						selected && ($formData.houseHeatType = selected.value)
					}}
				>
					<Select.Input name={attrs.name} />
					<Select.Trigger
						{...attrs}
						class="col-start-1 row-start-1 mx-2 inline-flex items-center justify-end self-stretch py-4 text-right text-base text-w-blue-950 !outline-none transition-colors focus:bg-neutral-100 focus:ring-0 focus-visible:outline-none
						{selectedHouseHeatType.value === '' && 'text-w-blue-950/40'} {selectedHouseHeatType.value &&
							'row-start-2 !pb-2 !pt-0'}"
						aria-label={t('web.forms.choose')}
					>
						{selectedHouseHeatType.label}
						<div class="flex min-w-6 justify-end">
							<ChevronDown color="#061c3e" class="h-4 w-4" />
						</div>
					</Select.Trigger>
					<Select.Content
						transition={flyAndScale}
						class="z-[99] rounded-xl border border-neutral-300 bg-neutral-100 p-2 shadow-xl"
						sideOffset={8}
						sameWidth={true}
						side="bottom"
						avoidCollisions={false}
					>
						{#each Object.entries(heatTypeOptions) as [value, label]}
							<Select.Item
								data-testid="heatTypeOptions-{value}"
								class="rounded-button flex h-10 w-full cursor-pointer select-none items-center rounded-md py-6 pl-5 pr-1.5 text-sm outline-none transition-all duration-75 data-[highlighted]:bg-neutral-200 md:py-3 {value ===
									'' && 'pointer-events-none opacity-60'}"
								{value}
								{label}
							>
								{label}
								<Select.ItemIndicator asChild={false} class="ml-auto">
									{#if selectedHouseHeatType.value !== ''}<Check
											color="#061c3e"
											class="h-4"
										/>{/if}</Select.ItemIndicator
								>
							</Select.Item>
						{/each}
					</Select.Content>
				</Select.Root>
			</Label>
		</Control>
		<FieldErrors />
	</Field>

	<Field {form} name="annualElectricityConsumption">
		<Control let:attrs>
			<Label
				class="grid w-full items-center overflow-hidden border-b border-neutral-200 bg-white ring-inset focus-within:bg-neutral-100"
			>
				<span
					class="z-10 col-start-1 row-start-1 mx-2 my-1 flex h-fit w-fit items-center whitespace-nowrap text-wrap rounded-md bg-white px-2 py-1 text-sm text-w-blue-950/70"
					>{t('web.forms.annualConsumption.withMwh')}</span
				>
				<input
					{...attrs}
					type="text"
					bind:value={$formData.annualElectricityConsumption}
					data-testid="input-pv-annual-consumption"
					placeholder="7"
					class="col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right text-base font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
				/>
				<FieldErrors class="-mt-4 py-1 pl-4 text-left text-xs text-w-red-500" />
			</Label>
		</Control>
	</Field>

	<Field {form} name="zip">
		<Control let:attrs>
			<Label
				class="grid w-full items-center overflow-hidden rounded-b-md border-b border-neutral-200 bg-white ring-inset focus-within:bg-neutral-100 sm:rounded-b-lg"
			>
				<span
					class="z-10 col-start-1 row-start-1 mx-2 flex h-[2em] w-fit items-center whitespace-nowrap rounded-md bg-white px-2 text-sm text-w-blue-950/70"
					>{@html t('web.forms.zipcode')}</span
				>
				<input
					{...attrs}
					type="text"
					bind:value={$formData.zip}
					data-testid="input-pv-zipcode"
					placeholder={t('web.forms.zipcode.placeholder')}
					class="col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right text-base font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
				/>
				<FieldErrors class="-mt-4 py-1 pl-4 text-left text-xs text-w-red-500" />
			</Label>
		</Control>
	</Field>

	<button
		class:pointer-events-none={sendingForm}
		class:opacity-70={sendingForm}
		data-testid="header-form-pv-submit"
		type="submit"
		class="btn-new btn-new-red mt-6 w-full whitespace-normal rounded-md sm:rounded-lg {!loaded &&
			'pointer-events-none animate-pulse bg-white/20 text-w-blue-950'}"
	>
		{#if sendingForm}
			<img src="/images/icons/loader-2.svg" class="w-[1.25em] animate-spin" alt="Loader icon" />{t(
				'web.forms.loading.devices'
			)}
		{:else}
			{t('web.forms.showPhotovoltaics')}
		{/if}
	</button>
</form>
