<script lang="ts">
	import { setContext } from 'svelte'
	import { writable } from 'svelte/store'

	type Tab = {
		index: number
		title: string
		icon?: string
		iconDark?: string
		testid?: string
	}

	export let justify: 'start' | 'center' | 'end' = 'start'
	export let selectedTab = 0
	export let showTabs = true
	export let paddingHorizontal = 'px-2 sm:px-10'

	let tabs: Tab[] = []
	let activeTab = writable(selectedTab)
	let startIndex = -1

	let tabContext = {
		setIndex: () => {
			startIndex += 1
			return startIndex
		},
		registerTab: (tab: Tab) => {
			tabs.push(tab)
			tabs = tabs
		},
		setActiveTab: (index: number) => {
			activeTab.set(index)
		},
		activeTab
	}

	setContext('tabContext', tabContext)

	$: activeTab.subscribe((value) => {
		selectedTab = value
	})

	let tabElements: HTMLButtonElement[] = []
</script>

{#if showTabs}
	{#if tabs.length !== 1}
		<div
			class="hide-scrollbar mb-4 flex w-full sm:mb-4 sm:justify-{justify} overflow-x-scroll {paddingHorizontal}"
		>
			<div class="relative flex h-14 w-full rounded-lg bg-white/10 sm:rounded-xl">
				{#if tabs.length === 0}
					<div
						class="flex h-full w-full animate-pulse flex-row justify-around gap-2 whitespace-nowrap p-1.5"
					>
						<div class="h-full w-full rounded-sm bg-neutral-200 opacity-30 sm:rounded-lg" />
						<div class="h-full w-full rounded-sm bg-neutral-200 opacity-30 sm:rounded-lg" />
					</div>
				{:else}
					{#each tabs as tab, i}
						<button
							class="custom-transition z-10 m-1.5 flex w-full items-center justify-center gap-x-2 whitespace-nowrap rounded-md text-xs @container 2xs:text-sm sm:rounded-lg {$activeTab ===
							tab.index
								? 'bg-white text-w-blue-950'
								: 'bg-transparent'}"
							on:click={() => {
								tabContext.setActiveTab(i)
							}}
							bind:this={tabElements[i]}
							data-testid={tab?.testid || null}
						>
							<img
								src={tab.iconDark && $activeTab === tab.index ? tab.iconDark : tab.icon}
								alt={tab.title}
								class="hidden aspect-square h-[1.4em] min-h-[16px] @3xs:block"
							/>
							{tab.title}
						</button>
					{/each}
					<!-- <div
				class="absolute z-0 my-2 box-content h-10 w-1/2 rounded-lg bg-white/35 supports-[transition-all]:transition-all"
				bind:this={background}
			/> -->
				{/if}
			</div>
		</div>
	{/if}
{/if}
<div class="grid grid-cols-1">
	<slot />
</div>

<style>
	.custom-transition {
		transition: background-color 0.15s linear;
	}
</style>
