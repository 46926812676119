<script lang="ts">
	import ConditionalAnchor from '$lib/components/ConditionalAnchor.svelte'
	import { CONTACT_VARS } from '$lib/consts'
	import { DOCUMENTS, PRIVACY_POLICY } from '$lib/routes'
	import { t } from '$lib/translations'
	import ContactField from './contact-field/ContactField.svelte'
	import FormHVAC from './sub-forms/FormHVAC.svelte'
	import FormPV from './sub-forms/FormPV.svelte'
	import FormTabs from './tabs'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let title: string | undefined = t('web.homepage.woltadvisorHeader')
	export let showHVAC = true
	export let showHiddenTabs = true
	export let showPV = false
	export let forcewidth = true
	export let rating: {
		text?: string
		link?: string
		stars?: number
		image?: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}
	export let showContactFields = false
	export let splitTestActive = false
	export let ABleadBeforeMP = false
	if (splitTestActive === false) showContactFields = false
	export let selectedSegment = 0
	const contactVars = CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE] || CONTACT_VARS['WOLTAIR_CZ']
</script>

<div class="woltadvisor-calc {forcewidth && 'forcewidth'} h-fit bg-w-blue-950 px-0 py-10 sm:py-10">
	<div class="heading-container flex w-full justify-center px-6">
		<h2 class="unstyled mt-0 text-center text-4xl-res font-semibold text-white sm:text-3.5xl">
			{@html title}
		</h2>
	</div>
	{#if rating?.text || rating?.stars || rating?.image?.data?.attributes?.url}
		<div class="mt-6 flex w-full justify-center px-2 sm:px-6">
			<ConditionalAnchor
				condition={!!rating.link}
				href={rating.link}
				target={rating.link?.includes('http') ? '_blank' : '_self'}
				cls="group"
			>
				<div class="flex flex-wrap items-center justify-center gap-x-4 gap-y-1">
					{#if rating.image?.data?.attributes?.url}
						<img
							src={rating.image?.data.attributes?.url}
							alt="rating logo"
							class="h-fit object-contain"
							height="32"
							width="80"
						/>
					{/if}
					{#if rating.stars}
						<div class="flex items-center gap-2">
							<div class="flex flex-col gap-1">
								<div class="flex gap-1">
									{#each Array(Math.floor(rating.stars)) as _}
										<img
											src="/images/icons/rating-star.svg"
											class="aspect-square h-[1em]"
											width="16"
											height="16"
											alt="rating"
											loading="lazy"
										/>
									{/each}
									{#if Math.floor(rating.stars) !== Math.ceil(rating.stars)}
										<img
											src="/images/icons/rating-star-half.svg"
											class="aspect-square h-[1em]"
											width="16"
											height="16"
											alt="rating"
											loading="lazy"
										/>
									{/if}
								</div>
							</div>
						</div>
					{/if}

					{#if rating.text}
						<span
							class="flex items-center gap-2 gap-x-3 text-sm underline-offset-2 {!!rating.link &&
								'group-hover:underline'}"
							>{@html rating.text}
							{#if !!rating.link}
								<img
									src="/images/icons/arrow/chevron-white.svg"
									alt="arrow icon"
									class="h-3 transition-transform duration-100 ease-in-out group-hover:translate-x-1"
								/>
							{/if}
						</span>
					{/if}
				</div>
			</ConditionalAnchor>
		</div>
	{/if}
	<div class="relative mt-8 w-full">
		<FormTabs.Tabs
			justify="center"
			bind:selectedTab={selectedSegment}
			showTabs={showHiddenTabs}
			paddingHorizontal="px-2 sm:px-6"
		>
			{#if showHVAC || showHiddenTabs}
				<FormTabs.TabItem
					title={t('web.forms.heatPump') || ''}
					testid={'header-form-hvac-tab'}
					icon="/images/icons/white/heat_pump.svg"
					iconDark="/images/icons/dark-blue/heat_pump.svg"
				>
					{#if showHVAC}
						<FormHVAC {ABleadBeforeMP} />
					{:else if showHiddenTabs}
						<div class="w-full px-6 text-center sm:px-6">
							<p class="unstyled">{@html t('web.forms.HVACnotAvailable.message')}</p>
							<p class="unstyled mb-6 pt-2 text-sm font-light opacity-70">
								{@html t('web.forms.PVnotAvailable.subtext')}
							</p>
							<ContactField type="HVAC" />
						</div>
					{/if}
				</FormTabs.TabItem>
			{/if}
			{#if showPV || showHiddenTabs}
				<FormTabs.TabItem
					title={t('web.forms.photovoltaics') || ''}
					testid={'header-form-pv-tab'}
					icon="/images/icons/white/flash.svg"
					iconDark="/images/icons/dark-blue/flash.svg"
				>
					{#if showPV}
						<FormPV {ABleadBeforeMP} />
					{:else if showHiddenTabs}
						<div class="w-full px-6 text-center sm:px-6">
							<p class="unstyled">{@html t('web.forms.PVnotAvailable.message')}</p>
							<p class="unstyled mb-6 pt-2 text-sm font-light opacity-70">
								{@html t('web.forms.PVnotAvailable.subtext')}
							</p>
							<ContactField type="PV" />
						</div>
					{/if}
				</FormTabs.TabItem>
			{/if}
		</FormTabs.Tabs>
	</div>
	<p class="unstyled mt-6 w-full px-10 text-center text-sm">
		{#if showContactFields}
			{@html t('web.forms.conditions', {
				class: 'text-w-red-500',
				link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
			})}
		{:else}
			{@html t('web.forms.callUs', {
				phone: `<a class="whitespace-nowrap text-w-red-500" href="${contactVars.phoneRef}">${contactVars.phoneNum}</a>`,
				email: `<a href="mailto:${contactVars.mail}" class="text-w-red-500">${contactVars.mail}</a>`
			})}
		{/if}
	</p>
</div>

<style lang="postcss">
	.text-align-last-right {
		text-align-last: right;
	}
	.woltadvisor-calc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		border-radius: 16px;
		overflow: hidden;
		color: #fff;
		width: 100%;
		max-width: 471px;
		&.forcewidth {
			@screen sm {
				width: 550px;
			}
		}
	}
</style>
