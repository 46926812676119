<script lang="ts">
	import { getContext } from 'svelte'

	type TabContext = {
		setIndex: () => number
		registerTab: (tab: Object) => void
		setActiveTab: (index: number) => void
		activeTab: any
	}

	export let title: string
	export let icon: string
	export let iconDark: string
	export let testid: string | null = null
	let { setIndex, registerTab, activeTab }: TabContext = getContext('tabContext')

	const tabIndex = setIndex()

	const tab = {
		index: tabIndex,
		testid,
		title,
		icon,
		iconDark
	}

	registerTab(tab)
</script>

<div
	class="custom-transition relative col-start-1 row-start-1 {$activeTab === tabIndex
		? 'pointer-events-auto opacity-100'
		: 'pointer-events-none opacity-0'}"
>
	<slot />
</div>

<style>
	.custom-transition {
		transition: opacity 0.15s linear;
	}
</style>
