<script lang="ts">
	import { string, object } from 'yup'
	import { sendGTM } from '$lib/analytics'
	import { t } from '$lib/translations'

	export let type: 'HVAC' | 'PV' = 'HVAC'

	const schema = object({
		email: string().email(t('web.forms.email.validate')).required(t('web.forms.email.required')),
		funding: string(),
		leadSource: string()
	})

	let formData: {
		email?: string
		funding: string
		leadSource: string
	} = {
		email: '',
		funding: {
			HVAC: 'TEPELNE_CERPADLO',
			PV: 'FOTOVOLTAIKA'
		}[type],
		leadSource: {
			HVAC: 'HVAC_INTERESTED',
			PV: 'FVE_INTERESTED'
		}[type]
	}

	let formErrors: Record<string, any> = {}

	let formSent = false
	let formDone = false

	async function validateField(fieldName: string, value: string) {
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			logger.debug('woltadvisor.contactField.validateField.failed', error.message)
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit(event: Record<string, any>) {
		event.preventDefault()

		try {
			formErrors = {}
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					await (key, value.toString() || '')
				})
			)

			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formSent = true

			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(formData)
			})

			if (response.ok) {
				formSent = false
				formDone = true
				sendGTM(
					'mini_calculator_submit',
					{},
					{
						calculator: {
							category: {
								HVAC: 'HVAC',
								PV: 'FVE'
							}[type],
							email_address: formData.email
						},
						_clear: true
					}
				)
			} else {
				formSent = false
				formDone = false
			}
		} catch (error: any) {
			if (error.inner) {
				error.inner.forEach((err: any) => {
					formErrors[err.path] = err.message
				})
			}
		}
	}
</script>

<div class="relative">
	<div
		class="pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
	>
		<div class="relative flex aspect-square w-[64px] items-center">
			<div
				class="absolute inset-x-0 mx-auto aspect-square w-full animate-spin rounded-full border-[6px] border-white border-t-transparent opacity-0 transition-opacity"
				class:opacity-100={formSent && !formDone}
			/>
		</div>
		<p class="absolute top-10 w-10/12 text-center text-base opacity-0" class:opacity-100={formDone}>
			{t('web.forms.leaveContact.thankYou')}
		</p>
	</div>
	<form
		on:submit|preventDefault={handleSubmit}
		class:opacity-0={formSent || formDone}
		class:pointer-events-none={formSent || formDone}
		class="transition-opacity"
	>
		<div class="grid grid-cols-1 gap-x-4 gap-y-2 @md:gap-y-6">
			<input
				bind:value={formData.email}
				on:change={() => {
					validateField('email', formData.email?.toString() || '')
				}}
				class="input"
				type="text"
				name="email"
				placeholder={t('web.forms.email.placeholder')}
				class:!text-w-red-500={formErrors.email}
			/>
			{#if formErrors.email}
				<p class="mt-1 text-sm text-w-red-500">{formErrors.email}</p>
			{/if}
		</div>
		<button type="submit" class="btn-new btn-new-red mt-6 w-full">{t('web.forms.letMeKnow')}</button
		>
	</form>
</div>

<style lang="scss">
	.input {
		@apply w-full appearance-none rounded-lg border border-[rgba(255,255,255,.3)] bg-transparent p-4 text-sm text-white shadow-sm outline-none ring-0 transition-colors duration-500;

		&::placeholder {
			@apply text-[rgba(255,255,255,.4)];
		}

		&:focus {
			@apply shadow-none outline outline-1 outline-offset-0 outline-white;
		}
	}
</style>
