<script lang="ts">
	export let href: string | null = null
	export let condition: boolean | null = null
	export let target: '_self' | '_blank' | '_parent' | '_top' = '_self'
	export let cls: string = ''
</script>

{#if condition}
	<a {href} {target} class={cls}>
		<slot />
	</a>
{:else}
	<div class={cls}>
		<slot />
	</div>
{/if}
